<template>
  <div class="dialog-container">
    <el-dialog
      title="添加商品"
      :visible.sync="show"
      @open="openDialog"
      @opened="initData"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="850px"
    >
      <div class="search-container">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="商品名称" prop="name">
                <el-input
                clearable
                placeholder="请输入商品名称"
                v-model="form.name"
                :maxlength="20"
              ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="productCode" label="商品编码">
                <el-input
                clearable
                placeholder="请输入商品编码"
                v-model="form.productCode"
                :maxlength="8"
              ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="text-right">
              <el-button
              type="primary"
              @click="searchEvent"
              class="search-button"
              >{{ $t("search") }}</el-button
            >
              <el-button type="primary" @click="cleanForm" class="clean-button">{{
                $t("clean")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="table-container">
        <el-table
          ref="table"
          :data="tableData"
          style="width: 100%"
          border
          :row-key="getRowKeys"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="index" align="center" label="序号" width="55"> </el-table-column>
          <el-table-column type="selection" :reserve-selection="true" align="center" width="55"> </el-table-column>
          <el-table-column prop="code" align="left" min-width="80" label="编码"> </el-table-column>
          <el-table-column
            prop="name"
            align="left"
            header-align="center"
            min-width="200"
            label="商品名称"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            align="left"
            header-align="center"
            min-width="120"
            label="商品图片"
          >
            <template slot-scope="scope">
              <img class="table-img" :src="scope.row.pic" alt="">
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="unitText"
            min-width="80"
            show-overflow-tooltip
            label="单位"
          >
          </el-table-column>
          <el-table-column align="center" prop="sellPrice" min-width="120" label="价格（元）">
          </el-table-column>
          <el-table-column align="center" prop="costPrice" min-width="120" label="成本（元）">
          </el-table-column>
        </el-table>
      </div>
      <div class="pageination-wrapper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total"
        >
        </el-pagination>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{ $t("sure") }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{ $t("cancel") }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import PageClass from '@/unit/pageClass'
import { changeInfo } from "@/utils/index";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    data: {
      required: true,
      type: Object,
      default: () => {
        return {
          eventTime: '',
          venueId: '',
        }
      },
    },
    selectData: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      flag: false,
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      apis,
      tableData: [],
      selectedArr: [],
      getRowKeys(row) {
        return row.id;
      },
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
    },
    /**
     * @function 分页尺寸修改方法
     * @param {Number} pageSize 修改后的分页大小
     */
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize
      // 业务问题，每次修改分页大小后要不要重新返回第一页
      this.form.pageNum = 1
      this.searchData()
    },
    /**
     * @function 当前页修改方法
     * @param {Number} pageNum 修改后的当前页
     */
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.form.pageNum = pageNum
      this.searchData()
    },
    handleSelectionChange(arr) {
      console.log(arr)
      this.selectedArr = arr
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$emit('getSelectData', this.selectedArr)
      this.closeDialog();
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.venueProductInfoPage, {
        ...this.form,
        type: 1,
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows && res.data.rows.map((item) => {
            let curItem = {}
            if (this.selectData.length) {
              curItem = this.selectData.find(s => s.id === item.id)
            }
            return {
              ...item,
              num: (curItem && curItem.num) || 0,
              totalPrice: (curItem && curItem.totalPrice) || '-',
              unitText: changeInfo(item.unit, this.DROPDOWNBOX.PRODUCT_UNIT, "value", "label"),
            }
          })
          this.form.total = res.data.total;
        }
      });
    },
    clearTable() {
      if (this.$refs.table) {
        this.$refs.table.clearSelection()
      }
    },
    initData() {
      this.form = {
        ...this.form,
        eventTime: this.data.eventTime,
        ...new PageClass(),
      }
      if (this.selectData.length) {
        this.$refs.table.clearSelection()
        if (this.selectData) {
          this.selectData.forEach((row) => {
            this.$refs.table.toggleRowSelection(row);
          });
        }
      } else {
        this.$refs.table.clearSelection()
      }
      this.searchData()
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new AddDialogClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.table-img {
  width: 50px;
  height: 50px;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
