<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="选择时间" prop="eventTime">
              <el-date-picker
                v-model="form.eventTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="storeId" label="仓库">
              <el-select @change="clearTable" @clear="clearTable" v-model="form.storeId" style="width: 100%">
                <el-option v-for="item in wareHouseList" :key="item.id" :label="item.venueName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="text-right">
            <!-- <el-button
              type="primary"
              @click="searchEvent"
              class="search-button"
              >{{ $t("search") }}</el-button
            > -->
            <el-button
              type="primary"
              @click="changeAddDialog(true)"
              class="search-button"
              v-if="buttonAuth.includes('enWareHouse:add')"
              >添加商品</el-button
            >
            <el-button type="primary" @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 20"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="code"
          align="left"
          min-width="80"
          label="编码"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          align="left"
          header-align="center"
          min-width="220"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="unitText"
          min-width="80"
          show-overflow-tooltip
          label="单位"
        >
        </el-table-column>
        <el-table-column
          props="num"
          align="center"
          width="200"
          label="数量"
        >
        <template slot-scope="scope">
          <el-input-number :step="1" step-strictly size="mini" v-model="scope.row.num" @input="numChange($event, scope.$index)" controls-position="right" :min="0" :max="999"></el-input-number>
        </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sellPrice"
          min-width="120"
          label="价格（元）"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="costPrice"
          min-width="120"
          label="成本（元）"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="totalPrice"
          min-width="120"
          label="合计金额（元）"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="120px"
          fixed="right"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button size="mini" v-repeatClick round type="primary" @click="delByArr([scope.row.id], 1)">{{$t('delete')}}</el-button>
          </template>
				</el-table-column>
      </el-table>
    </div>
    <div class="pageination-wrapper">
        <el-button
          class="default-button"
          v-repeatClick
          :disabled="selectedArr.length === 0"
          v-if="buttonAuth.includes('enWareHouse:submit')"
          @click="submitEnhouse(selectedArr.map((item) => item.id))"
          >{{ $t("submit") }}</el-button
        >
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :data="form"
      :selectData="tableData"
      @closeDialog="changeAddDialog"
      @getSelectData="getSelectData"
    ></add-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import IndexClass from "./indexClass.js";
import apis from "@/apis";
import addDialog from "./dialog/addDialog.vue";
// import { changeInfo } from "@/utils/index";

export default {
  mixins: [mixin],
  components: {
    addDialog,
  },
  data() {
    return {
      form: new IndexClass("form"),
      rules: new IndexClass("rules"), // 实例化一个表单的规则
      tableData: [],
      addDialog: {
        visible: false,
      },
      num: '',
      selectIndexArr: [],
      wareHouseList: [],
    };
  },
  computed: {
    ...mapState(["Language", "venueId"]),
  },
  methods: {
    // 计算合计金额
    numChange(v, i) {
      if (v) {
        this.tableData[i].totalPrice = (v * this.tableData[i].costPrice).toFixed(2)
      } else {
        this.tableData[i].totalPrice = '-'
      }
    },
    // 获取子页面传过来的选中参数
    getSelectData(data) {
      this.tableData = data
    },
    // 获取仓库
    getWareHouse() {
      this.$http.post(apis.venuevManagementList, {}).then((res) => {
        if (res.data.code === 0) {
          this.wareHouseList = res.data.rows
        }
      })
    },
    // 提交
    // 删除表格中所提交数据
    submitEnhouse(id) {
      if (this.form.eventTime) {
        // 数据处理
        const params = this.getParams()
        const index = params.findIndex(item => item.stockQty < 1)
        if (index > -1) {
          this.$message.warning('数量不能小于1')
          return
        }
        this.$http.post(apis.venueProductStockAdd, {
          venueProductStockDTOList: params,
          eventTime: this.form.eventTime,
          type: 1, //1: 入库，2：出库
        }).then((res) => {
          if (res.data.code === 0) {
            this.delByArr(id)
          }
        })
      } else {
        this.$message.warning('请选择时间')
      }
    },
    // 格式化提交参数
    getParams() {
      return this.selectedArr && this.selectedArr.map((item) => {
        return {
          productCode: item.code,
          productId: item.id,
          stockQty: item.num,
          venueId: this.form.storeId,
        }
      })
    },
    delByArr(id, type = 0) {
      this.tableData = this.tableData.filter(item => !id.includes(item.id))
      // type: 1: 删除，0：提交
      if (type) {
        this.$message.success('删除成功！')
      } else {
        this.$message.success('提交成功！')
      }
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus) {
      if (dialogStatus) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$nextTick(() => {
              this.addDialog.visible = dialogStatus;
            });
          }
        })
      } else {
        this.addDialog.visible = dialogStatus;
      }
    },
    clearTable() {
      this.tableData = []
      this.selectedArr = []
      this.$refs.addDialog.clearTable()
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new IndexClass("form");
      this.clearTable()
    },
    searchEvent() {

    },
  },
  mounted() {
    this.getWareHouse()
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-wrapper {
  width: 100%;
}
.pageination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
